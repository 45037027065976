import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
	{
		path: '/:lang?',
		name: 'channel',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Channel.vue')
	},
	{
		path: '/:lang?/category/:slug?',
		name: 'category',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Category.vue')
	},
	{
		path: '/:lang?/asset/:slug?',
		name: 'asset',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Asset.vue')
	},
	{
		path: '/notfound',
		name: 'notfound',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/NotFound.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
