<template>
	<div 	id="app"
			:class="this.sidebar ? 'sidebar-open' : null"
			:style="backgroundImage() && isPrePostLive() ? backgroundImage() : null"
			v-if="loaded">
		<Header />
		<search-assets />
		<router-view :key="$route.fullPath" class="main" />
		<Footer />
	</div>
	<Error :error="error" v-else-if="error" />
	<Loading v-else />
</template>
<style lang="scss">
	@import '@/assets/scss/main.scss';
</style>
<script>
	import Error from '@/components/Error.vue'
	import Footer from '@/components/Footer.vue'
	import Header from '@/components/Header.vue'
	import SearchAssets from '@/components/SearchAssets.vue'
	import Loading from '@/components/Loading.vue'
	export default {
		name: 'App',
		components: {
			Error,
			Footer,
			Header,
			Loading,
			SearchAssets
		},
		computed: {
			channel() {
				return this.$store.getters.channel
			},
			loaded() {
				return this.$store.getters.loaded
			},
			sidebar() {
				return this.$store.getters.sidebar
			}
		},
		data () {
			return {
				error: null,
				currentLive: null
			}
		},
		methods: {
			getParams() {
				const isSSO = this.$route.query.sso_type && this.$route.query.sso_token || localStorage.getItem('sso_token') && localStorage.getItem('sso_type');
				let data = { slug: window.location.pathname.split('/').pop() }
				if (this.$route.query.token) {
					data.token = this.$route.query.token
					this.setAttendee(data.token)
				}
				if (this.$route.query.password) data.password = this.$route.query.password
				if (isSSO) this.axiosAddSSOInterceptor()
				return data
			},
			setAttendee(token) {
				try {
					const attendee = JSON.parse(atob(token.split('.')[1]))
					this.$store.dispatch('setAttendee', attendee)
				}
				catch (err) {
					console.log(err);
				}
			},
			getChannel() {
				let password = new URLSearchParams(window.location.search).get('password')
				password = this.$route.query.password ? this.$route.query.password : password
				if (password) this.$store.dispatch('password', password)
				this.$store.dispatch('loaded', false)
				this.$store.dispatch('channel')
				.then(response => {
					window.document.title = response.title
					this.setTheme(response)
					this.setFonts(response)
				})
				.catch(error => {
					console.log(error)
				})
				.finally(() => {
					this.$store.dispatch('loaded', true)
				})
			},
			setTheme(data) {
				const primary = data.skin_color_primary ? '#' + data.skin_color_primary : '#5EB787'
				const secondary = data.skin_color_secondary ? '#' + data.skin_color_secondary : '#F4ABAD'
				const text = data.skin_color_text ? '#' + data.skin_color_text : '#000000'
				const background = data.skin_color_background ? '#' + data.skin_color_background : '#FFFFFF'
				const alt = data.skin_color_alternate_background ? '#' + data.skin_color_alternate_background : '#FFFFFF'
				const dark = data.skin_color_dark ? '#' + data.skin_color_dark : '#333333'
				const light = data.skin_color_light ? '#' + data.skin_color_light : '#F2F2F2'
				const borderRadius = data.skin_border_radius ? data.skin_border_radius : '4px'
				document.documentElement.style.setProperty('--color-primary', primary)
				document.documentElement.style.setProperty('--color-secondary', secondary)
				document.documentElement.style.setProperty('--color-text', text)
				document.documentElement.style.setProperty('--color-background', background)
				document.documentElement.style.setProperty('--color-alt', alt)
				document.documentElement.style.setProperty('--color-dark', dark)
				document.documentElement.style.setProperty('--color-light', light)
				document.documentElement.style.setProperty('--border-radius', borderRadius)
			},
			setFonts(data) {
				let primaryFont = false
				let secondaryFont = false
				let fontStyle = document.createElement('style')
				if(data.fonts.length) {
					data.fonts.forEach(font => {
						const fontFace = new FontFace(this.genLabel(font.label), `url(${data.font_path}${font.filename})`)
						fontFace.load().then(() => {
							fontStyle.appendChild(document.createTextNode(`@font-face{font-family: '${this.genLabel(font.label)}'; src: url('${data.font_path}${font.filename}'); font-weight: ${this.genWeight(font.weight)}}`));
							if(font.primary) {
								primaryFont = true
								document.documentElement.style.setProperty('--font-primary', this.genLabel(font.label))
							} else {
								secondaryFont = true
								document.documentElement.style.setProperty('--font-secondary', this.genLabel(font.label))
							}
						})
					})
					if(!primaryFont) { document.documentElement.style.setProperty('--font-primary', this.genLabel(data.fonts[0].label)) }
					if(!secondaryFont) { document.documentElement.style.setProperty('--font-secondary', this.genLabel(data.fonts[0].label)) }
				} else {
					document.documentElement.style.setProperty('--font-primary', 'HelveticaNeue, Helvetica, Arial, Sans-serif')
					document.documentElement.style.setProperty('--font-secondary', 'HelveticaNeue, Helvetica, Arial, Sans-serif')
				}
				document.head.appendChild(fontStyle)
			},
			genLabel(label) {
				return label.substring(0, label.indexOf('-'))
			},
			genWeight(weight) {
				if(weight === 'thin') return 100
				if(weight === 'extralight') return 200
				if(weight === 'light') return 300
				if(weight === 'medium') return 500
				if(weight === 'semibold') return 600
				if(weight === 'bold') return 700
				if(weight === 'extrabold') return 800
				if(weight === 'black') return 900
				return 400
			},
			genFileType() {
				const ext = this.asset.background ? this.asset.background.url.split('.').pop() : null
				if(ext) return this.asset.background.type + '/' + ext
				return null
			},
			isPrePostLive() {
				return (this.asset && [0, 2, 3].includes(this.asset.broadcasting) && (this.asset.type === 5 || this.asset.status_id !== 5)) ? true : false
			},
			backgroundImage() {
				const background = this.asset && this.asset.background && this.asset.background.type === "image" && this.asset.background.url
				if(background) return 'background-image: url('+this.asset.background.url+')'
				if(this.asset && this.asset.poster) return 'background-image: url('+this.asset.poster+')'
				return null
			},
			stripTitle(title) {
				let regex = /(<([^>]+)>)/ig
				return title.replace(regex, "")
			},
			redirectUrls() {
				const path = location.pathname.split('/').slice(1)
				if(path[2] === 'its_all_about_tech_distinguishing_winners_and_losers_in_a_crowded_field_') this.$router.push({ name: 'channel', params: { lang: 'a9fb9bdb-0f2e-4d81-adb9-2d3575b6567b' }})
				if(path[2] === 'industriell_transformation_perspektiv_fran_golvet') this.$router.push({ name: 'channel', params: { lang: '8ea00746-f0a7-4df5-a653-6344fc5edf7f' }})
				if(path[2] === 'living_and_breathing_data_challenges_and_opportunities_') this.$router.push({ name: 'channel', params: { lang: '76e0a3c5-e08e-4060-a192-4658618277b9' }})
				if(path[2] === 'multistrategi_effektiv_riskspridning') this.$router.push({ name: 'channel', params: { lang: '34ce3cd1-92dc-44d6-8cb3-344e393436d6' }})
				if(path[2] === 'macro_sets_the_stage_top-down_and_bottom-up_reflections_') this.$router.push({ name: 'channel', params: { lang: '9d9a9b03-ea6f-4ed5-bbf7-88c4e5880c99' }})
				if(path[2] === 'bms_explainer_08') this.$router.push({ name: 'channel', params: { lang: 'ab218888-1c35-4d8e-b8e3-eb840401cd34' }})
			}
		},
		created() {
			this.redirectUrls()
		},
		mounted() {
			this.getChannel()
		}
	}
</script>
