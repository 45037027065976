import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import i18n from './plugins/i18n'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faFileExport, faCheck, faInfoCircle, faTimes, faSyncAlt, faEye, faEyeSlash, faTrash, faSort, faBars, faSpinner, faVideo, faExpand, faCompress, faImage, faFilePdf, faFileImage, faFile, faFilePowerpoint, faFileArchive, faFileAudio, faPlay, faFileDownload, faCalendarPlus, faComment, faFileVideo, faUser, faPen, faPhone, faBuilding, faEnvelope, faCalendar, faChevronLeft, faChevronRight, faCog, faDownload, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faPlus, faFileExport, faCheck, faInfoCircle, faTimes, faSyncAlt, faEye, faEyeSlash, faTrash, faSort, faBars, faSpinner, faVideo, faExpand, faCompress, faImage, faFilePdf, faFileImage, faFile, faFilePowerpoint, faFileArchive, faFileAudio, faPlay, faFileDownload, faCalendarPlus, faComment, faFileVideo, faUser, faPen, faPhone, faBuilding, faEnvelope, faCalendar, faChevronLeft, faChevronRight, faCog, faDownload, faCaretDown, faCaretRight)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import moment from 'moment'
import VueMoment from 'vue-moment'
require('moment/locale/sv')
moment.locale('sv')
Vue.use(VueMoment, { moment })

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
axios.defaults.baseURL = process.env.VUE_APP_API

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
	offset: 0
})

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { name: 'custom', lodash: lodash })

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
	position: 'top-right',
	action : {
    text : '',
    href: '#',
    onClick : (e, toastObject) => {
      toastObject.goAway(0);
    },
    class: 'announcement'
  },
  duration: 5000
})

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
	i18n
}).$mount('#app')
