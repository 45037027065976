<template>
	<footer :class="[channel.skin_background_footer ? 'bg-'+channel.skin_background_footer : 'bg-dark', channel.skin_footer_text ? 'text-'+channel.skin_footer_text : 'text-light']"
			>
		<b-container class="px-9">
			<b-row 	class="py-6">
				<b-col cols="12" class="text-center pb-6"><h6 class="font-roboto font-weight-bold">OM BRUMMER PLAY</h6></b-col>
				<b-col cols="12" md="4" class="text-center text-075 pl-md-8">
					<div class="small">Brummer &amp; Partners</div>
					<div class="small">Norrmalmstorg 14</div>
					<div class="small">Box 7030</div>
					<div class="small">SE-103 86 Stockholm</div>
					<div class="small">org.nr 556627-2182</div>
				</b-col>
				<b-col cols="12" md="4" class="text-center px-8">
					<p class="text-075">På Brummer Play samlar vi våra filmer. Här hittar du de senaste intervjuerna med förvaltningsansvariga i Brummer &
						Partners fonder och mycket mer.</p>
					<p><a href="https://www.brummer.se/" target="_blank" class="text-white">brummer.se</a></p>
					<p><a href="#" target="_blank" class="text-white" data-toggle="modal" data-target="#disclaimerModal">Disclaimer</a> / <a
							href="#" target="_blank" class="text-white" data-toggle="modal" data-target="#cookiesModal">Cookies</a></p>
					<p class="text-075">Producerad av <a href="http://creo.se" target="_blank">Creo Media Group</a></p>
				</b-col>
				<b-col cols="12" md="4" class="text-center text-075 pr-md-8">
					<p class="mb-2 small">Kontakta oss</p>
					<p class="mb-2 small"><a href="tel:+46856621480">08-566 214 80</a></p>
					<a href="mailto:info@brummer.se">info@brummer.se</a>
				</b-col>
			</b-row>
			<hr class="border-white text-075">
			<b-row class="d-flex justify-content-center">
				<b-col cols="auto" class="pb-3 pt-5">
					<img :src="getLogo()" :style="'height: 44px'" />
				</b-col>
			</b-row>
		</b-container>
	</footer>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			channel() {
				return this.$store.getters.channel
			},
			stream() {
				return this.$store.getters.stream
			}
		},
		methods: {
			getLogo() {
				return this.channel && this.channel.logos && this.channel.logos.length && this.channel.logos_path ? this.channel.logos_path + this.channel.logos[0].filename : null
			},
		}
	}
</script>
