<template>
	<div id="loading" class="justify-content-center align-items-center" :class="inline ? 'inline' : null">
		<b-container class="text-center">
			<span class="dot" :class="loaded > 0 ? '' : 'hidden'"></span>
			<span class="dot" :class="loaded > 1 ? '' : 'hidden'"></span>
			<span class="dot" :class="loaded > 2 ? '' : 'hidden'"></span>
		</b-container>
	</div>
</template>
<script>
	export default {
		props: {
			inline: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loaded: 0
			}
		},
		methods: {
			initLoading() {
				setInterval(() => {
					this.loaded < 3 ? this.loaded++ : this.loaded = 0
				}, 1000)
			}
		},
		mounted() {
			this.loaded = 0
			this.initLoading()
		}
	}
</script>
