var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "main align-center",
      class: _vm.inline ? "inline" : null,
      attrs: { id: "error" },
    },
    [
      _c("b-container", { staticClass: "py-5 text-center" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("error.title")) + "...")]),
        _c("p", { staticClass: "m-0 pt-3 lead" }, [
          _vm._v(
            _vm._s(
              _vm.error === "asset"
                ? _vm.$t("error.asset-text")
                : _vm.error === "category"
                ? _vm.$t("error.category-text")
                : _vm.error === ""
                ? _vm.$t("error.channel-text")
                : _vm.$t("error.other-text")
            ) + "."
          ),
        ]),
        _c("p", { staticClass: "m-0" }, [
          _vm._v(_vm._s(_vm.$t("error.help")) + " "),
          _c(
            "a",
            { attrs: { href: "mailto:support@creo.se", target: "_blank" } },
            [_vm._v("support@creo.se")]
          ),
          _vm._v("."),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }