import Vue 		from 'vue'
import VueI18n 	from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
	'en-US': 	require('@/lang/en.json'),
	'en-GB': 	require('@/lang/en.json'),
	'en': 		require('@/lang/en.json'),
	'sv-SE': 	require('@/lang/sv.json'),
	'sv': 		require('@/lang/sv.json')
}

const i18n = new VueI18n({
  locale: 'en-US',
  fallbackLocale: 'en-US',
  silentFallbackWarn: true,
  messages
})

export default i18n
