var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.searchAssets && _vm.searchAssets.length
    ? _c(
        "div",
        {
          staticClass:
            "pt-4 py-md-5 bg-gradient-white-gray position-relative z-index-2",
        },
        [
          _c(
            "b-container",
            { staticClass: "px-9" },
            [
              _c(
                "b-row",
                { ref: "assetsRow" },
                _vm._l(_vm.searchAssets, function (asset, akey) {
                  return _c(
                    "b-col",
                    {
                      key: "search" + akey,
                      ref: "asset",
                      refInFor: true,
                      staticClass: "pb-5 mb-5 ",
                      attrs: { cols: "12", md: "4", lg: "2" },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn-asset",
                          attrs: {
                            to: {
                              name: "asset",
                              params: { slug: asset.asset_category_key },
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "embed-responsive embed-responsive-16by9 d-flex justify-content-center align-items-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "embed-responsive embed-responsive-16by9 d-flex justify-content-center align-items-center bg-cover",
                                  class: !asset.thumbnail_url
                                    ? "bg-gray-lightest"
                                    : null,
                                  style: asset.thumbnail_url
                                    ? "background-image: url(" +
                                      asset.thumbnail_url +
                                      ");"
                                    : null,
                                },
                                [
                                  !asset.thumbnail_url
                                    ? _c("font-awesome-icon", {
                                        staticClass: "img-icon",
                                        attrs: { icon: _vm.getIcon(asset) },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("font-awesome-icon", {
                                staticClass:
                                  " position-absolute img-icon z-index-1 ",
                                attrs: { icon: "caret-right" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "pt-2 font-weight-normal font-roboto small",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getCustomFeildValue(
                                    asset.customfields,
                                    "title_sv",
                                    asset.title
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("hr"),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }