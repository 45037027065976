<template>
	<header :class="headerClasses()">
		<b-container>
			<b-row align-v="center" class="font-roboto">
				<b-col class="flex-grow-1 " v-if="getLogo()">
					<img :src="getLogo()" :style="channel.skin_header_logo_height ? 'height: ' + channel.skin_header_logo_height : null" />
				</b-col>
				<b-col cols="auto">
					<a href="https://www.brummer.se/" class="back-to-brummer-link font-roboto">{{$t('header.backToBrummer')}}</a>
				</b-col>
				<b-col cols="auto">
					<form class="search-form">
						<b-form-input type="text" v-model="search" class="search-input font-roboto"  :placeholder="$t('form.search')" />
					</form>
				</b-col>
			</b-row>
		</b-container>
	</header>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			category() {
				return this.$store.getters.category
			},
			channel() {
				return this.$store.getters.channel
			},
		},
		data() {
			return {
				search: null
			}
		},
		methods: {
			getSearchAssets(event) {
				this.$store.dispatch('searchAssets', { search: event, key: this.channel.categories[0].categories[this.$route.path === '/en' ? 1 : 0].category_key, type: 2 })
					.catch(err => {
						console.log('err', err);
					})
			},
			getLogo() {
				return this.channel && this.channel.logos && this.channel.logos.length && this.channel.logos_path ? this.channel.logos_path + this.channel.logos[0].filename : null
			},
			headerClasses() {
				return [
					this.channel && this.channel.skin_background_header ? 'bg-'+this.channel.skin_background_header : 'bg-light',
					this.channel && this.channel.skin_header_text ? 'text-'+this.channel.skin_header_text : 'text-dark'
				]
			},
		},
		watch: {
			search(event) {
				this.getSearchAssets(event)
			},
			'$route.name': {
				handler: function () {
					this.search = null
					this.getSearchAssets(false)
				},
				deep: true,
				immediate: true
			},
		}
	}
</script>
