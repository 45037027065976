var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      class: [
        _vm.channel.skin_background_footer
          ? "bg-" + _vm.channel.skin_background_footer
          : "bg-dark",
        _vm.channel.skin_footer_text
          ? "text-" + _vm.channel.skin_footer_text
          : "text-light",
      ],
    },
    [
      _c(
        "b-container",
        { staticClass: "px-9" },
        [
          _c(
            "b-row",
            { staticClass: "py-6" },
            [
              _c(
                "b-col",
                { staticClass: "text-center pb-6", attrs: { cols: "12" } },
                [
                  _c("h6", { staticClass: "font-roboto font-weight-bold" }, [
                    _vm._v("OM BRUMMER PLAY"),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-center text-075 pl-md-8",
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c("div", { staticClass: "small" }, [
                    _vm._v("Brummer & Partners"),
                  ]),
                  _c("div", { staticClass: "small" }, [
                    _vm._v("Norrmalmstorg 14"),
                  ]),
                  _c("div", { staticClass: "small" }, [_vm._v("Box 7030")]),
                  _c("div", { staticClass: "small" }, [
                    _vm._v("SE-103 86 Stockholm"),
                  ]),
                  _c("div", { staticClass: "small" }, [
                    _vm._v("org.nr 556627-2182"),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-center px-8",
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c("p", { staticClass: "text-075" }, [
                    _vm._v(
                      "På Brummer Play samlar vi våra filmer. Här hittar du de senaste intervjuerna med förvaltningsansvariga i Brummer & Partners fonder och mycket mer."
                    ),
                  ]),
                  _c("p", [
                    _c(
                      "a",
                      {
                        staticClass: "text-white",
                        attrs: {
                          href: "https://www.brummer.se/",
                          target: "_blank",
                        },
                      },
                      [_vm._v("brummer.se")]
                    ),
                  ]),
                  _c("p", [
                    _c(
                      "a",
                      {
                        staticClass: "text-white",
                        attrs: {
                          href: "#",
                          target: "_blank",
                          "data-toggle": "modal",
                          "data-target": "#disclaimerModal",
                        },
                      },
                      [_vm._v("Disclaimer")]
                    ),
                    _vm._v(" / "),
                    _c(
                      "a",
                      {
                        staticClass: "text-white",
                        attrs: {
                          href: "#",
                          target: "_blank",
                          "data-toggle": "modal",
                          "data-target": "#cookiesModal",
                        },
                      },
                      [_vm._v("Cookies")]
                    ),
                  ]),
                  _c("p", { staticClass: "text-075" }, [
                    _vm._v("Producerad av "),
                    _c(
                      "a",
                      { attrs: { href: "http://creo.se", target: "_blank" } },
                      [_vm._v("Creo Media Group")]
                    ),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-center text-075 pr-md-8",
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c("p", { staticClass: "mb-2 small" }, [
                    _vm._v("Kontakta oss"),
                  ]),
                  _c("p", { staticClass: "mb-2 small" }, [
                    _c("a", { attrs: { href: "tel:+46856621480" } }, [
                      _vm._v("08-566 214 80"),
                    ]),
                  ]),
                  _c("a", { attrs: { href: "mailto:info@brummer.se" } }, [
                    _vm._v("info@brummer.se"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("hr", { staticClass: "border-white text-075" }),
          _c(
            "b-row",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "b-col",
                { staticClass: "pb-3 pt-5", attrs: { cols: "auto" } },
                [
                  _c("img", {
                    style: "height: 44px",
                    attrs: { src: _vm.getLogo() },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }