<template>
	<div  class="pt-4 py-md-5 bg-gradient-white-gray position-relative z-index-2" v-if="searchAssets && searchAssets.length ">
		<b-container class="px-9">
			<b-row ref="assetsRow">
				<b-col cols="12" md="4" lg="2" ref="asset" class="pb-5 mb-5 " :key="'search'+akey" v-for="(asset, akey) in searchAssets">
					<router-link :to="{ name: 'asset', params: { slug: asset.asset_category_key } }" class="btn-asset">
						<div
							class="embed-responsive embed-responsive-16by9 d-flex justify-content-center align-items-center">
							<div class="embed-responsive embed-responsive-16by9 d-flex justify-content-center align-items-center bg-cover"
								:class="!asset.thumbnail_url ? 'bg-gray-lightest' : null"
								:style="asset.thumbnail_url ? 'background-image: url('+asset.thumbnail_url+');' : null">
								<font-awesome-icon :icon="getIcon(asset)" class="img-icon"
									v-if="!asset.thumbnail_url" />
							</div>
							<font-awesome-icon icon="caret-right" class=" position-absolute img-icon z-index-1 " />
						</div>
						<span class="pt-2 font-weight-normal font-roboto small">{{ getCustomFeildValue(asset.customfields,  'title_sv',  asset.title)}}</span>
						<hr>
					</router-link>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
export default {
	computed: {
		searchAssets() {
			return this.$store.getters.searchAssets
		}
	},
	methods: {
		getCustomFeildValue(data, field, alt) {
			if (_.find(data, { slug: field }) && _.find(data, { slug: field }).value && _.find(data, { slug: field }).value.length) return _.find(data, { slug: field }).value
			return alt? alt : ''
		},
		getIcon(asset) {
			if (asset.type === 1) return 'video'
			if (asset.type === 2) return 'image'
			if (asset.type === 4) return 'podcast'
			if (asset.type === 5) return 'users'
			if (asset.type === 7) return 'audio'
			return 'file'
		},
	},

}
</script>
