<template>
	<section id="error" class="main align-center" :class="inline ? 'inline' : null">
        <b-container class="py-5 text-center">
            <h1>{{ $t('error.title') }}...</h1>
            <p class="m-0 pt-3 lead">{{ error === 'asset' ? $t('error.asset-text') : error === 'category' ? $t('error.category-text') : error === '' ? $t('error.channel-text') : $t('error.other-text') }}.</p>
            <p class="m-0">{{ $t('error.help') }} <a href="mailto:support@creo.se" target="_blank">support@creo.se</a>.</p>
        </b-container>
	</section>
</template>
<script>
    export default {
		props: {
			inline: {
				type: Boolean,
				default: false
			},
			error: {
                type: String,
                default: null
            }
		},
        methods: {
            setTheme() {
				const primary = '#5EB787'
				const secondary = '#F4ABAD'
				const text = '#000000'
				const background = '#FFFFFF'
				document.documentElement.style.setProperty('--color-primary', primary)
				document.documentElement.style.setProperty('--color-secondary', secondary)
				document.documentElement.style.setProperty('--color-text', text)
				document.documentElement.style.setProperty('--color-background', background)
			}
        },
        mounted() {
            this.setTheme()
        }
    }
</script>
