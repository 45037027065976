var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { class: _vm.headerClasses() },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "font-roboto", attrs: { "align-v": "center" } },
            [
              _vm.getLogo()
                ? _c("b-col", { staticClass: "flex-grow-1 " }, [
                    _c("img", {
                      style: _vm.channel.skin_header_logo_height
                        ? "height: " + _vm.channel.skin_header_logo_height
                        : null,
                      attrs: { src: _vm.getLogo() },
                    }),
                  ])
                : _vm._e(),
              _c("b-col", { attrs: { cols: "auto" } }, [
                _c(
                  "a",
                  {
                    staticClass: "back-to-brummer-link font-roboto",
                    attrs: { href: "https://www.brummer.se/" },
                  },
                  [_vm._v(_vm._s(_vm.$t("header.backToBrummer")))]
                ),
              ]),
              _c("b-col", { attrs: { cols: "auto" } }, [
                _c(
                  "form",
                  { staticClass: "search-form" },
                  [
                    _c("b-form-input", {
                      staticClass: "search-input font-roboto",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("form.search"),
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }